import React, { useEffect, useState, useMemo } from 'react'
import { chunk } from 'lodash'


export default function AssociatedBrands({ asscoiatedBrands }) {

    const [current, setCurrent] = useState(0)

    const currentLogos = useMemo(() => {
        return chunk(asscoiatedBrands, 3).map((brands, index) => {
            return { brands, id: index }
        })
    }, [asscoiatedBrands])

    useEffect(() => {
        setInterval(() => {
            setCurrent((prev) => {
                if (currentLogos.length === prev + 1) {
                    return 0
                }
                return prev + 1
            })
        }, 3000)
    }, [currentLogos])

    return (
        <div className='flex justify-center w-100'>
            <div className='w-100' >
                <div className='w-100 h-100 pos-rel' >
                    {currentLogos.map((brands) => {
                        return (
                            <div key={brands.id} className={'flex justify-center  w-100  m-gap-x-0 top0  ' + (brands.id === 0 ? 'pos-rel ' : 'pos-abs ') + (current === brands.id ? 'op-1  trans-opacity-enter ' : 'op-0 trans-opacity-exit')} >
                                {brands.brands.map((brand,index) => {
                                    return (
                                        <AssociatedBrand className='max-300 w-100 m-mb0 shrink-1' key={brand.name+index} brand={brand} />
                                    )
                                })}
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

function AssociatedBrand({ brand, className = '' }) {
    return (
        <div className={' mb20 ' + className}>
            <img alt='' src={brand?.icon?.url} className='brand-logo'/>
            <p className='m0'>
                {brand?.name}
            </p>
        </div>
    )
}